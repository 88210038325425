import React from 'react';
import styled from 'styled-components';
import { dimensions } from '../shared/styles.js';

const SectionWrapper = styled.section`
  display : flex;
  flex-direction : row;
  align-items : flex-start;
  justify-content : center;
  padding-left : 10rem;
  padding-right : 10rem;

  @media screen and (max-width: 425px) {
    padding-left : 4rem;
    padding-right : 4rem;
  }
`;

const SectionContent = styled.div`
  flex-basis : ${dimensions.contentWidth}px;
  flex-grow : 0;
  margin : auto;
  max-width : ${dimensions.contentWidth}px;
`;

const Section = ({children, wrapperStyle = {}, WrapperComponent = SectionWrapper, ...props}) => (
  <WrapperComponent style={wrapperStyle}>
    <SectionContent {...props}>
      {children}
    </SectionContent>
  </WrapperComponent>
);

const ColumnsSection = styled(Section)`
  display : flex;
  flex-direction : row;
  justify-content : space-between;
  align-items : flex-start;
  width : 100%;

  @media screen and (max-width: 425px) {
    flex-direction : column;
  }
`;

const SectionColumn = styled.div`
  flex : 1;
  margin-left : 4rem;
  margin-right : 4rem;

  &:first-child {
    margin-left : 0;
  }

  &:last-child {
    margin-right : 0;
  }

  @media screen and (max-width: 425px) {
    margin-left : 0rem;
    margin-right : 0rem;
    margin-top   : 5rem;
    margin-bottom : 5rem;
  }
`;



export default Section;
export { SectionWrapper, ColumnsSection, SectionColumn };
