import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { ColumnsSection } from '../components/Section';

import HeaderLogo from './HeaderLogo';

const HeaderContainer = styled.header`
  position : absolute;
  z-index : 1;
  top : 0;
  left : 0;
  width : 100%;
  font-size : 1.9rem;
  background-image: url('${require('../images/header_background.png')}');
  background-size : contain;
  background-repeat : repeat-x;
`;

const HeaderSection = styled(ColumnsSection)`
  align-items : center;
`;

const Column = styled.div`
  flex : 1;
  display : flex;
  flex-direction : row;
  justify-content : space-between;

  &:first-child {
    padding-right : 2rem;
  }

  &:last-child {
    padding-left : 2rem;
  }

  @media screen and (max-width: 425px) {
    display : none;
  }
`;

const HeaderLink = styled(Link)`
  color : black;
  text-decoration : none;
  text-transform: uppercase;

  &:hover {
    font-weight : bolder;
  }
`;

const Burger = styled.img`
  width : 4rem;
  height : 4rem;
`;

const BurgerWrapper = styled.div`
  position : absolute;
  top : 0;
  right : 0;
  padding : 3rem;

  @media screen and (min-width: 426px) {
    display : none;
  }
`


const Header = ({onBugerClick}) => (
  <HeaderContainer>
    <HeaderSection>
      <Column>
        <HeaderLink to="/#home">Home</HeaderLink>
        <HeaderLink to="/presentation">Qui sommes-nous ?</HeaderLink>
        <HeaderLink to="/offre">Notre offre</HeaderLink>
      </Column>
      <HeaderLogo/>
      <Column>
        <HeaderLink to="/clients">Réferences</HeaderLink>
        <HeaderLink to="/blog">Blog</HeaderLink>
        <HeaderLink to="/contact">Contactez-nous</HeaderLink>
      </Column>
      <BurgerWrapper>
        <Burger onClick={onBugerClick} src={require('../images/burger_menu.png')}/>
      </BurgerWrapper>
    </HeaderSection>
  </HeaderContainer>
)

export default Header
